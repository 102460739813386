import React from "react";

const Leaf = ({ fill, secondaryFill, rotate }) => (
  <svg
    className="leaf"
    style={{ transform: `rotate(${rotate}deg)` }}
    xmlns="http://www.w3.org/2000/svg"
    width="500px"
    height="100%"
    viewBox="0 0 697 409"
    fill="none"
  >
    <path
      d="M341.858 408.239C170.834 408.239 70.1682 287.385 70.1682 204.146C70.1682 120.908 170.834 6.10352e-05 341.858 6.10352e-05C516.684 6.05016 696.992 218.907 696.992 218.907C696.992 218.907 512.882 408.185 341.858 408.185V408.239Z"
      fill={fill}
    />
    <path
      d="M1.72192 237.051C16.2454 232.345 31.1772 230.531 48.6732 225.971C149.995 199.567 253.689 200.634 410.927 199.021C231.763 172.753 223.716 155.693 50.7161 179.693C33.7161 182.051 18.9157 182.703 8.06348 195.507C-2.54797 208.026 1.72192 237.051 1.72192 237.051Z"
      fill={secondaryFill}
    />
  </svg>
);

export default Leaf;
