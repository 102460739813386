import React from "react";

const QuoteIcon = ({ isLeafRed }) => {
  const fill = isLeafRed ? "#FFFF" : "#FF585D";
  return (
    <svg className="quoteIcon" xmlns="http://www.w3.org/2000/svg" width="50" height="43" viewBox="0 0 50 43" fill="none">
      <g clip-path="url(#clip0_219_110)">
        <path
          d="M19.84 0L12.09 31.465L10.23 22.475C13.2267 22.475 15.7067 23.405 17.67 25.265C19.6333 27.125 20.615 29.605 20.615 32.705C20.615 35.805 19.6333 38.3367 17.67 40.3C15.7067 42.2633 13.2783 43.245 10.385 43.245C7.38833 43.245 4.90833 42.2633 2.945 40.3C0.981667 38.2333 0 35.7017 0 32.705C0 31.6717 0.0516666 30.69 0.155 29.76C0.361666 28.83 0.671666 27.745 1.085 26.505C1.49833 25.1617 2.06667 23.56 2.79 21.7L10.075 0H19.84ZM49.135 0L41.385 31.465L39.525 22.475C42.5217 22.475 45.0017 23.405 46.965 25.265C48.9283 27.125 49.91 29.605 49.91 32.705C49.91 35.805 48.9283 38.3367 46.965 40.3C45.0017 42.2633 42.5733 43.245 39.68 43.245C36.6833 43.245 34.2033 42.2633 32.24 40.3C30.2767 38.2333 29.295 35.7017 29.295 32.705C29.295 31.6717 29.3467 30.69 29.45 29.76C29.6567 28.83 29.9667 27.745 30.38 26.505C30.7933 25.1617 31.3617 23.56 32.085 21.7L39.37 0H49.135Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_219_110">
          <rect width="50" height="43" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default QuoteIcon;
