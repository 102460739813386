// React
import React from "react";

// Components
import Logo from "../images/Logo";
import Form from "../components/Form";
import formTree from "../images/Form_Tree.png";

// Styles
import "./styles.css";

const FormPage = ({ fetchData, closeForm, setIsLoading, isLoading }) => {
	return (
		<div className="formPage">
			<Logo className="logo" />
			<Form
				fetchData={fetchData}
				closeForm={closeForm}
				setIsLoading={setIsLoading}
				isLoading={isLoading}
			/>
			<img className="formTree" alt="avocadoTree" src={formTree} />
		</div>
	);
};

export default FormPage;
