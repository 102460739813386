// React
import React from "react";

const FooterButton = () => {
	return (
		<a
			target="_blank"
			href="https://www.nhsconfed.org/commercial/community-of-practice">
			<button className="footer-btn">
				<div className="footer-glass-btn-effect-thick"></div>
				Learn more
			</button>
		</a>
	);
};

export default FooterButton;
