// React
import React, { useEffect } from "react";

// Components
import Messages from "../Messages";
import WelcomeBoard from "./WelcomeBoard";
import Footer from "../Footer/Footer";

// Styles
import "./styles.css";

const GratitudeTree = ({
	messages,
	openForm,
	animationStart,
	setAnimationStart,
}) => {
	const contentClasses = animationStart ? "content animation" : "content";

	const zoomLeaves = () => {
		setAnimationStart(true);
	};

	const handleScroll = () => {
		if (window.scrollY > 0) {
			/*setAnimationStart(true);*/
		}
	};

	useEffect(() => {
		window.addEventListener("scroll", handleScroll);

		// Cleanup event listener on component unmount
		return () => {
			window.removeEventListener("scroll", handleScroll);
		};
	}, []);

	return (
		<div className={contentClasses}>
			<WelcomeBoard zoomLeaves={zoomLeaves} />
			{animationStart && <Messages messages={messages} />}
			<div onClick={openForm} className="messageButton">
				<div className="glasseffectbold"></div>
				<div className="glasseffectthin"></div>
				Leave a<br />
				message
			</div>
		</div>
	);
};

export default GratitudeTree;
