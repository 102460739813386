export const getRandomMargin = () => {
  const margins = [0, -150, 75, -75, -100];
  const randomIndex = Math.floor(Math.random() * margins.length);
  return margins[randomIndex];
};

export const getRandomRotation = (previousRotation) => {
  const rotations = [45, 30, 195, 330, 300, 240, 215];
  let randomIndex = Math.floor(Math.random() * rotations.length);
  while (rotations[randomIndex] === previousRotation) {
    randomIndex = Math.floor(Math.random() * rotations.length);
  }
  return rotations[randomIndex];
};

export const getRandomColorPair = (previousColors) => {
  const colorPairs = {
    "#6EC4C0": "#7ACDC9",
    "#684495": "#7652A3",
    "#EB5961": "#F3656D",
    "#0D60A9": "#1467B0",
  };

  const colors = Object.keys(colorPairs);
  let randomIndex = Math.floor(Math.random() * colors.length);

  while (
    previousColors &&
    previousColors.length > 0 &&
    (previousColors.includes(colors[randomIndex]) ||
      previousColors.includes(colorPairs[colors[randomIndex]]))
  ) {
    randomIndex = Math.floor(Math.random() * colors.length);
  }

  const primaryColor = colors[randomIndex];
  const secondaryColor = colorPairs[primaryColor];

  return [primaryColor, secondaryColor];
};
