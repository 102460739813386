// React
import React from 'react'


const CloseButton = ({handleClick}) => {
  return (
    <button className='closeBtn'
    onClick={(e) => {
      e.stopPropagation();
      handleClick();
    }}
  >
    X
  </button>
  
  )
}

export default CloseButton