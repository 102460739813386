// React
import React, { useState, useEffect, useRef } from "react";

// Helpers
import {
  getRandomMargin,
  getRandomRotation,
  getRandomColorPair,
} from "./helpers";

// Components
import CloseButton from "./CloseButton";
import Message from "./Message";
import Overlay from "./Overlay";
import RandomIconComponent from "./RandomLeaf";

import Footer from "../Footer/Footer";

// custom Hook
import useIsMobile from "../../hooks/useIsMobile";

// Style
import "./styles.css";

const Messages = ({ messages }) => {
  const isMobile = useIsMobile();
  const [activeIndex, setActiveIndex] = useState(null);
  const [styles, setStyles] = useState([]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const previousColorRef = useRef();
  const previousRotationRef = useRef(null);
  const cardRefs = useRef([]);
  const animationElement = document.querySelector(".animation");

  useEffect(() => {
    const initialStyles = messages.map(() => {
      const [primaryColor, secondaryColor] = getRandomColorPair(
        previousColorRef.current
      );
      const rotate = getRandomRotation(previousRotationRef.current);

      previousColorRef.current = [primaryColor, secondaryColor];
      previousRotationRef.current = rotate;

      return {
        fill: primaryColor,
        secondaryFill: secondaryColor,
        rotation: rotate,
        marginTop: getRandomMargin(),
      };
    });
    setStyles(initialStyles);
  }, [messages]);

  useEffect(() => {
    if (animationElement) {
      if (activeIndex !== null && cardRefs.current[activeIndex]) {
        animationElement.style.overflowY = "hidden";
        setIsPopupOpen(false);
      } else {
        animationElement.style.overflowY = "auto";
        setIsPopupOpen(true);
      }
    }
  }, [activeIndex, animationElement]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("parallax-show");
          }
          // else {
          //   entry.target.classList.remove('parallax-show');
          // }
        });
      },
      { threshold: 0.05 }
    );

    cardRefs.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      cardRefs.current.forEach((card) => {
        if (card) observer.unobserve(card);
      });
    };
  }, [cardRefs.current]);

  const handleZoomIn = (index) => {
    setActiveIndex(index);
  };

  const handleZoomOut = () => {
    setActiveIndex(null);
  };

  return (
    <>
      <div className="animate messagesContainer">
        <div className="messagesWrapper">
          {messages.map((item, index) => {
            const { marginTop, rotation, fill, secondaryFill } =
              styles[index] || {};
            const isActive = activeIndex === index;

            const truncatedMessage =
              item.message.length > 130
                ? `${item.message.slice(0, 130)}...`
                : item.message;
            const message =
              isActive || isMobile ? item.message : truncatedMessage;

            const name = item?.name;

            const isLeafRed = fill === "#EB5961";

            const currentYPosition = animationElement?.scrollTop;

            const cardStyle = {
              // marginTop: isActive ? "" : marginTop,
              transform: isActive ? "translate(-50%, 15%) scale(1.65)" : "",
              zIndex: isActive ? 2 : 1,
              opacity: !isPopupOpen && (isActive ? 0.95 : 0.4),
              position: isActive ? "absolute" : "relative",
              top: isActive ? currentYPosition : undefined,
              left: isActive ? "50%" : undefined,
              transition: isPopupOpen
                ? "opacity 1s ease, transform 1s ease"
                : "transform 0.3s ease",
            };

            const textContainerStyle = {
              transform: isActive ? "scale(0.67)" : "scale(1)",
              textAlign: isActive || isMobile ? "left" : "center",
              transition: "transform 0.6s ease",
            };

            // const numberOfLeafs =
            //   document.getElementsByClassName("messageCard").length;
            // const element = document.getElementById("bottom");

            // if (element != null) {
            //   if (numberOfLeafs > 3) {
            //     element.classList.remove("absOnl");
            //     element.classList.add("relOnl");
            //   }
            // }

            return (
              <div
                ref={(el) => (cardRefs.current[index] = el)}
                className="messageCard"
                key={index}
                style={cardStyle}
                onClick={!isMobile ? () => handleZoomIn(index) : undefined}
              >
                {isActive && (
                  <>
                    <CloseButton handleClick={handleZoomOut} />
                  </>
                )}
                <Message
                  message={message}
                  name={name}
                  style={textContainerStyle}
                  isActive={isActive}
                  isLeafRed={isLeafRed}
                  isMobile={isMobile}
                />
                <RandomIconComponent
                  fill={fill}
                  secondaryFill={secondaryFill}
                  rotate={rotation}
                  isActive={isActive}
                />
              </div>
            );
          })}
        </div>

        <Overlay activeIndex={activeIndex} />
      </div>
      <Footer
        className="footer-leaf"
        style={{
          zIndex: activeIndex ? "-1" : "2",
        }}
      />
    </>
  );
};

export default Messages;
