// React
import React, { useEffect } from "react";

// Components
import Logo from "../../images/Logo";
import avocadoTree from "../../images/Avocado_Tree.png";
import avocadoTreeMobile from "../../images/Avocado_Tree _mobile.png";

// Custom Hook
import useIsMobile from "../../hooks/useIsMobile";

const WelcomeBoard = ({ zoomLeaves }) => {
  const isMobile = useIsMobile();

  // Added this solution to address the issue of hiding elements on Firefox
  useEffect(() => {
    const element = document.getElementById("wrap");
    element.addEventListener("animationend", () => {
      element.style.display = "none";
    });

    return () => {
      element.removeEventListener("animationend", () => {
        element.style.display = "none";
      });
    };
  }, []);

  return (
    <>
      <Logo className="logo" />
      <div id="wrap" className="wrap">
        <h1 className="heading">
          Share your appreciation <br className="mobile-hide" />
          leave your message for the NHS
        </h1>
        <h2 className="subHeading">
          Share your gratitude or suggestions for the NHS. Your <br className="mobile-hide" />
          voice counts. Join us in supporting those who serve <br className="mobile-hide" />
          our communities tirelessly.
        </h2>
      </div>
      <img onClick={zoomLeaves} className="avocadoTree" alt="avocadoTree" src={isMobile ? avocadoTreeMobile : avocadoTree} />
    </>
  );
};

export default WelcomeBoard;
