import {
	collection,
	getDocs,
	addDoc,
	query,
	orderBy,
} from "firebase/firestore";
import { firestore } from "./firebase";

export const fetchData = async () => {
	const messagesCollection = collection(firestore, "messages");
	const q = query(messagesCollection, orderBy("timestamp", "desc"));
	const messagesSnapshot = await getDocs(q);
	const messagesData = messagesSnapshot.docs
		.map((doc) => doc.data())
		.filter((message) => message.approved === true);
	return messagesData;
};

export const handleSave = async (
	e,
	nameRef,
	emailRef,
	messageRef,
	fetchData
) => {
	e.preventDefault();
	try {
		const messagesCollection = collection(firestore, "messages");
		await addDoc(messagesCollection, {
			name: nameRef.current.value,
			email: emailRef.current.value,
			message: messageRef.current.value,
			timestamp: new Date(),
			approved: false,
		});
		await fetchData();
	} catch (error) {
		console.error("Error adding document: ", error);
	}
};
