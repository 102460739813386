// React
import React from "react";

// Components
import Leaf from "../../icons/Leaf";
import ActiveLeaf from "../../icons/ActiveLeaf";

const RandomLeaf = ({ fill, stroke, secondaryFill, rotate, isActive }) => {
  const IconComponent = isActive ? ActiveLeaf : Leaf;

  return (
    <span>
      <IconComponent
        fill={fill}
        secondaryFill={secondaryFill}
        stroke={stroke}
        rotate={rotate}
      />
    </span>
  );
};

export default RandomLeaf;
