// Home.js
import React, { useState, useEffect } from "react";

// Service
import { fetchData } from "../firebase/messageService";

// Components
import GratitudeTree from "../components/GratitudeTree";
import FormPage from "./FormPage";

import Footer from "../components/Footer/Footer";

export default function Home() {
  const [messages, setMessages] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [animationStart, setAnimationStart] = useState(false);

  const fetchDataAndSetMessages = async () => {
    const messagesData = await fetchData();
    setMessages(messagesData);
  };

  useEffect(() => {
    fetchDataAndSetMessages();
  }, []);

  const openForm = () => {
    setShowForm(true);
  };

  const closeForm = () => {
    setShowForm(false);
    setAnimationStart(true);
  };

  return (
    <>
      {showForm ? (
        <>
          <FormPage fetchData={fetchDataAndSetMessages} closeForm={closeForm} setIsLoading={setIsLoading} isLoading={isLoading} /> <Footer />
        </>
      ) : (
        <>
          <GratitudeTree messages={messages} openForm={openForm} setAnimationStart={setAnimationStart} animationStart={animationStart} />{" "}
        </>
      )}
    </>
  );
}
