import React, { useRef, useState, useEffect } from "react";
import { handleSave } from "../../firebase/messageService";

import "./styles.css";
import CloseButton from "./CloseButton";

const Form = ({ fetchData, closeForm, isLoading, setIsLoading }) => {
  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();
  const [message, setMessage] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isMaxLengthExceeded, setIsMaxLengthExceeded] = useState(false);
  const [popup, setPopUp] = useState(true);

  const [isToggled, setIsToggled] = useState(false);
  const [isDelayedClass, setIsDelayedClass] = useState(false);

  const handleToggle = () => {
    setIsToggled(!isToggled);
    setIsDelayedClass(false);

    setTimeout(() => {
      setIsDelayedClass(true);
    }, 400);
  };

  let className = isToggled ? "toggledClass" : "gdpr-outer-container";
  if (isDelayedClass) {
    className += " gdpr-display-none";
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await handleSave(event, nameRef, emailRef, messageRef, fetchData);
      setIsSubmitted(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (message.length > 333) {
      setIsMaxLengthExceeded(true);
    } else {
      setIsMaxLengthExceeded(false);
    }
  }, [message]);

  const handleInputChange = (e) => {
    setMessage(e.target.value.slice(0, 334));
  };

  const isMessageEmpty = message.trim() === "";

  return (
    <>
      {isLoading && (
        <div className="formOverlay">
          <div className="loader"></div>
        </div>
      )}

      <div className={className}>
        <div className="gdpr-glass-effect-container">
          <div className="gdpr-thin-glass"></div>
          <div className="gdpr-thick-glass"></div>
        </div>
        <div className="button-container">
          <>
            <CloseButton onClick={handleToggle} />
          </>
        </div>
        <div className="gdpr-inner-container">
          <div className="gdpr-section">
            <h2 className="gdpr-title">GDPR Disclaimer for 'Tree of Hope'</h2>
            <h2 className="gdpr-subtitle">Data Controller</h2>
            <p className="gdpr-paragraph">Novartis IM UK Limited</p>
            <p className="gdpr-paragraph">
              2<sup className="gdpr-superscript">nd</sup> Floor, The WestWorks
              Building, White City Place, London
            </p>
          </div>
          <div className="gdpr-section">
            <h2 className="gdpr-subtitle">Purpose of Data Collection</h2>
            <p className="gdpr-paragraph">
              We collect and process personal data to manage and maintain a
              database of innovative ideas. This includes names and email
              addresses provided with the submissions.
            </p>
          </div>
          <div className="gdpr-section">
            <h2 className="gdpr-subtitle">Legal Basis for Processing</h2>
            <p className="gdpr-paragraph">
              The legal basis for processing your personal data is your consent,
              which you provide by submitting your innovative ideas to our
              database.
            </p>
          </div>
          <div className="gdpr-section">
            <h2 className="gdpr-subtitle">Data Usage</h2>
            <p className="gdpr-paragraph">
              The personal data you provide will be used solely for the purposes
              of operating and enhancing our database, including but not limited
              to:
            </p>
            <ul className="gdpr-list">
              <li>Storing and displaying submitted ideas</li>
              <li>
                Contacting submitters for further information or collaboration
              </li>
              <li>Sharing ideas with other users of the database</li>
            </ul>
          </div>
          <div className="gdpr-section">
            <h2 className="gdpr-subtitle">Data Retention</h2>
            <p className="gdpr-paragraph">
              Your personal data will be retained for as long as necessary to
              fulfil the purposes for which it was collected, or as required by
              applicable laws.
            </p>
          </div>
          <div className="gdpr-section">
            <h2 className="gdpr-subtitle">Data Sharing</h2>
            <p className="gdpr-paragraph">
              We will not share your personal data with third parties without
              your explicit consent, except where required by law.
            </p>
          </div>
          <div className="adverse-event-section">
            <p
              style={{ position: "relative", zIndex: "2" }}
              className="adverse-event-paragraph"
            >
              Adverse events should be reported. Reporting forms and information
              can be found at{" "}
              <a target="_blank" href="https://yellowcard.mhra.gov.uk/">
                www.mhra.gov.uk/yellowcard
              </a>
              .
            </p>
          </div>
        </div>
      </div>

      <div className="form-container">
        {isSubmitted ? (
          <div className="thank-you-message">
            <p className="header">Thank You For a Message!</p>
            <p className="subHeader">
              Share your gratitude to everyone who dedicates their lives to
              saving others
            </p>
            <button onClick={closeForm} className="formButton">
              Back to all messages
            </button>
          </div>
        ) : (
          <form onSubmit={handleSubmit}>
            <p className="header">Leave Your Message!</p>
            <p className="subHeader">
              Share your gratitude to everyone who dedicates their lives to
              saving others
            </p>
            <textarea
              className="input-like-textarea"
              placeholder="Type your message here ..."
              ref={messageRef}
              value={message}
              onChange={handleInputChange}
            />
            {isMaxLengthExceeded && (
              <p className="errorMsg">
                You have reached the maximum number of characters (334).
              </p>
            )}
            <input type="text" placeholder="Your name ..." ref={nameRef} />
            <input
              type="email"
              placeholder="Email address ..."
              ref={emailRef}
            />
            <button
              className="formButton"
              type="submit"
              disabled={isMessageEmpty}
            >
              Submit
            </button>
            <button onClick={closeForm} className="formButtonBack">
              Back to all messages
            </button>
          </form>
        )}
      </div>
    </>
  );
};

export default Form;
