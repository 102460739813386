import React from "react";
import FooterButton from "./FooterButton";

const Footer = ({ className, style }) => {
  return (
    <footer className={`${className} footer`} id="bottom" style={style}>
      <div className="footer-container-top">
        <div className="footer-display-flex-container">
          <div className="footer-content-container">
            <h2 className="footer-title">
              NHS Confederation and Novartis: Community of Practice
            </h2>
            <p className="footer-paragraph">
              Join our Community of Practice to share experiences, learn from
              best practice, and co-create solutions to benefit patients and the
              workforce alike
            </p>
          </div>
          <div className="footer-button-container">
            <FooterButton />
          </div>
        </div>
      </div>
      <div className="footer-container-bottom">
        <div className="footer-display-flex-container-bottom">
          <div className="footer-info">UK</div>
          <div className="footer-separator"></div>
          <div className="footer-info">May 2024</div>
          <div className="footer-separator"></div>
          <div className="footer-info">Content ID: 451979</div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
