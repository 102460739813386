// React
import React from "react";

const ToggleClassButton = ({ onClick }) => {
  return (
    <button className="gdpr-closeBtn" onClick={onClick}>
      X
    </button>
  );
};

export default ToggleClassButton;
