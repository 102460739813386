// React
import React from "react";

// Components
import QuoteIcon from "../../icons/QuoteIcon";

const Message = ({ message, name, style, isActive, isLeafRed, isMobile }) => {
  return (
    <div className="textContainer" style={style}>
      {isActive && <QuoteIcon isLeafRed={isLeafRed} />}
      <p className="message-text" style={isMobile ? { overflowY: "auto", maxHeight: "120px" } : {}}>
        {message}
      </p>

      {(isActive || isMobile) && <p className="name">{name}</p>}
    </div>
  );
};

export default Message;
