import React from "react";

const Leaf = ({ fill, secondaryFill, rotate }) => (
  <svg
    className="leaf"
    style={{ transform: `rotate(${rotate}deg)` }}
    xmlns="http://www.w3.org/2000/svg"
    width="600"
    height="100%"
    viewBox="0 0 1233 750"
    fill="none"
  >
    <path
      d="M688.457 731.205C386.208 806.368 155.221 637.156 118.66 490.138C82.0996 343.12 206.9 85.3288 509.149 10.1653C820.774 -55.9831 1232.92 240.726 1232.92 240.726C1232.92 240.726 990.683 655.946 688.434 731.109L688.457 731.205Z"
      fill={fill}
    />
    <path
      d="M12.1481 578.335C35.7481 563.641 61.3403 553.875 90.2582 538.132C257.726 446.967 441.452 403.279 718.629 331.324C390.457 363.67 368.742 337.075 73.5419 455.496C44.5339 467.133 18.6634 474.788 5.10822 502.172C-8.14626 528.949 12.1481 578.335 12.1481 578.335Z"
      fill={secondaryFill}
    />
  </svg>
);

export default Leaf;
